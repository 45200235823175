
/*--------------------- FONT --------------------------------*/


$baseFontSize: 14px;

$baseLineHeight: 18px;
$baseLineH1: 1.05;
$baseLineH2: 1.05;
$baseLineH3: 24px;
$baseLineH4: 20px;
$baseLineH5: 20px;
$baseLineSmall: 16px;



$baseWeight: 400;


/*Shadow*/

$shadowText: 0 2px 0 2px rgba(0, 0, 0, 0.25);
$shadowBlock: 0 4px 10px 0  rgba(0, 0, 0, 0.1);


/*COLOR LOADING*/

$colorMainBlue: #0056b9;
$colorMainBlueDark: #003265;
$colorBlack: #333;
$colorBlackLight: #333333;
$colorGrayDark: #5a5a5a;
$colorGrayLight: #d3d0c5;
$colorBrownLight: #897f60;
$colorRed: #dd3d3b;
$colorWhite: #ffffff;
$colorOrange: #ef7e24;
$colorYellow: #f2c94c;
$colorViolet: #bac8e8;
$colorFiolet: #7d4b74;


/*TRANSITION*/
$mainTransition: all 0.25s linear;
$transitionFilter: all 0.5s ease-in-out;
$transitionProjects: all 0.45s ease;
















