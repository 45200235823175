p {
  margin: 0 0 6px;
}
b, strong{
  font-weight: 600;
}
i {
  font-style: italic;
}
body{
  background-color: #fff;
  font-size: $baseFontSize;
  color: $colorBlack;
  font-family: "Open Sans";
  font-weight: $baseWeight;
  font-style: normal;
  line-height: $baseLineHeight;
}
.txt-bold{
  font-weight: bold;
}
textarea {
  border-radius: 2px;
  border: 1px solid $colorGrayLight;
  width: 100%;
  resize: none;
  padding: 10px 18px;
  margin-bottom: 22px;
  display: block;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  letter-spacing: normal;
  color: $colorBlackLight;
}
h1,
.h1 {
  line-height: $baseLineH1;
  margin: 24px 0 24px;
  font-size: 40px;
}
h2,
.h2 {
  font-size: 24px;
  margin: 30px 0 20px;
  line-height: $baseLineH2;
}
h3,
.h3 {
  font-size: 20px;
  line-height: $baseLineH3;
  margin: 15px 0 16px;
}
h4,
.h4 {
  line-height: $baseLineH4;
  font-size: 16px;
  margin: 0 0 16px;
}
h5,
.h5 {
  line-height: $baseLineH5;
  font-size: 16px;
}
small{
  font-size: 14px;
  line-height: $baseLineSmall;
}

@media screen and (max-width: 1023px){

}



@media screen and (max-width: 767px){

}
@media screen and (max-width: 479px){
  h1,
  .h1 {
    line-height: $baseLineH1;
    font-size: 28px;
    margin: 15px 0 20px;
  }
  h2,
  .h2 {
    font-size: 20px;
    line-height: 24px;
  }
  h3,
  .h3 {
    font-size: 16px;
    line-height: 20px;
  }
  h4,
  .h4 {
    font-size: 14px;
    line-height: 18px;
  }
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }




}






.float-left{
  float: left;
  padding: 0 30px 30px 0;
}
.float-right{
  float: right;
  padding: 30px 0 30px 30px;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}
.display-none{
  display: none;
}






.hidden{
  overflow: hidden;
}

.white-space {
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-uppercase{
  text-transform: uppercase;
}
.text-uppercase-not{
  text-transform: inherit;
}


.txt-black, .txt-black.icon-glyphter {
  color: $colorBlack;
}
.txt-black-light, .txt-black.icon-glyphter {
  color: $colorBlackLight;
}
.txt-white, .txt-white.icon-glyphter {
  color: $colorWhite;
}
.txt-red, .txt-white.icon-glyphter {
  color: $colorRed;
}
.txt-orange, .txt-orange.icon-glyphter{
  color: $colorOrange;
}
.txt-gray-dark, .txt-gray-dark.icon-glyphter{
  color: $colorGrayDark;
}
.txt-gray-light, .txt-gray-light.icon-glyphter{
  color: $colorGrayLight;
}

.icon-glyphter {
  font-family: 'Glyphter';
  text-transform: none !important;
  font-size: 30px;
  display: inline-block;
  color: $colorGrayLight;
  text-align: center;
  transition: $mainTransition;
  &.big-icon {
    font-size: 44px;
  }
}
*:focus,
*:active {
  outline: none!important;
}
a{
  cursor: pointer;
  text-decoration: none;
  transition: $mainTransition;
  border: none;
  color: $colorBlack;
  &.txt-white{
    &:hover{
      color: $colorGrayLight;
    }
  }
  &.txt-black{
    &:hover{
      color: $colorMainBlue;
    }
  }
  &.txt-black-light{
    &:hover{
      color: $colorMainBlue;
    }
  }

}
hr {
  background-color: $colorGrayLight;
  height: 1px;
  display: block;
  margin: 20px 0;
  border-width: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.table{
  overflow: auto;
}
table{
  background-color: #fff;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
  text-align: left;
  thead{
    tr{
      border-bottom: 1px solid #dbdbdb;
    }
    text-align: left;
    th{
      padding: 25px 35px;
    }

  }
  tbody{
    tr{
      border-bottom: 1px solid #dbdbdb;
      &:last-child{
        border-bottom: none;
      }
      td{
        padding: 10px 35px;

      }
    }
  }
}
@media screen and (max-width: 1279px){
  table{
    thead{
      th{
        padding: 20px 20px;
      }
    }
    tbody{
      tr{
        td{
          padding: 10px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  table{
    thead{
      th{
        padding: 15px 14px;
      }
    }
    tbody{
      tr{
        td{
          padding: 10px 14px;
        }
      }
    }
  }
}





* {
  box-sizing: border-box;
}

.btn{
  font-weight: 600;
  display: inline-block;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 11px 16px;
  cursor: pointer;
  transition: $mainTransition;
  color: #fff;
  background-color: $colorBlackLight;
  &:hover{
    background-color: $colorRed;
  }
}
.nowrap{
  white-space: nowrap;
  display: inline-block;
}
.btn-dotted{
  .btn-gray{
    padding: 0;
    display: inline-block;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: $mainTransition;
    color: $colorBlackLight;
    background-color: transparent;
    border-bottom: 1px dotted $colorBlackLight;

  }
  background-color: $colorGrayLight;
  padding: 7px 12px;
  border-radius: 4px;
  display: inline-block;
  &:hover{
    background-color: $colorRed;

    .btn-gray{
      color: $colorWhite;
      border-color: $colorWhite;
    }
  }
}



.btn-wide{
  width: 100%;
}


@media screen and (max-width: 1023px){

}



@media screen and (max-width: 767px){

}



















.form-group {
  position: relative;
  margin-bottom: 20px;
}
::-webkit-input-placeholder {
  color: $colorGrayLight;
}
::-moz-placeholder {
  color: $colorGrayLight;
}
:-ms-input-placeholder {
  color: $colorGrayLight;
}
:-moz-placeholder {
  color: $colorGrayLight;
}

input[type=text], input[type=number], input[type=password], input[type=email], input[type=url]{
  padding: 13px 40px 13px 20px;
  width: 100%;
  border: 1px solid $colorGrayLight;
}

input[type=text].jump-placeholder, input[type=number].jump-placeholder, input[type=password].jump-placeholder, input[type=email].jump-placeholder, input[type=url].jump-placeholder{
  padding: 18px 40px 10px 20px;
  border: 1px solid $colorGrayLight;
  width: 100%;
}
textarea.jump-placeholder{
  padding: 18px 40px 10px 20px;
  border: 1px solid $colorGrayLight;
  width: 100%;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=radio], input[type=checkbox]{
  width: 0;
  position: absolute;
  display: none;
  z-index: -1;
  opacity: 0;
}
input[type=radio]+label {
  position: relative;
  padding: 0 0 0 30px;
  display: inline-block;
  cursor: pointer;
  &:after{
    content: "";
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $colorWhite;
    top: 2px;
    left: 1px;
    box-shadow: $colorMainBlue 0 0 0 2px;
    border-radius: 50%;
    border: 3px solid $colorWhite;
    transition: $mainTransition;
  }
}
input[type=radio]:checked+label:after {
  background-color: $colorMainBlue;
}

input[type=checkbox]+label {
  position: relative;
  padding: 0 0 0 33px;
  display: inline-block;
  cursor: pointer;
  &:after{
    content: '';
    font-family: 'Glyphter';
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorMainBlue;
    text-align: center;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    background-color: $colorWhite;
    border: 2px solid $colorGrayLight;
  }
}
input[type=checkbox]:checked+label{
  &:after{
    content: '\0042';
    background-color: $colorMainBlue;
    border-color: $colorMainBlue;
    color: $colorWhite;
  }
}

.input-form{
  width: 100%;
}
.jump-placeholder{
  & + .placeholder{
    position: absolute;
    color: $colorGrayLight;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: $mainTransition;

  }
  &.focus-input{
    & + .placeholder{
      transform: translateY(-24px);
      font-size: 12px;
    }
  }
}

textarea.jump-placeholder{
  & + .placeholder{
    position: absolute;
    color: $colorGrayLight;
    top: 23px;
    left: 20px;
    pointer-events: none;
    transition: $mainTransition;

  }
  &.focus-input{
    & + .placeholder{
      transform: translateY(-24px);
      font-size: 12px;
    }
  }
}


span.input-invalid,
span.input-valid {
  position: absolute;
  background-color: $colorGrayLight;
  display: inline-block;
  bottom: -14px;
  padding: 0 10px;
  right: 0;
  text-align: right;
  font-size: 12px;
  color: $colorRed;
  z-index: 5;
}
.validate-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: $colorRed;
}
.validate-icon:after {
  content: '\0057';
  font-size: 15px;
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  color: $colorRed;
}
span.input-valid {

}
span.input-valid ~ .validate-icon:after {
  content: '\0042';

}
.bg-image-cover{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-image-cover-top{
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.relative{
  position: relative;
}
.bg-fiolet{
  background-color: $colorFiolet;
}
.bg-yellow{
  background-color: $colorYellow;
}
.bg-violet{
  background-color: $colorViolet;
}
.bg-orange{
  background-color: $colorOrange;
}
.bg-red{
  background-color: $colorRed;
}


::-webkit-scrollbar {
  position: fixed;
  width: 5px;
  height: 5px;
  cursor: pointer
}

::-webkit-scrollbar-track-piece {
  background: #d9d9db;
  -webkit-border-radius: 3px
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #7b7b7b;
  -webkit-border-radius: 3px;
  cursor: pointer
}

::-webkit-scrollbar-thumb:horizontal {
  width: 10px;
  background-color: #7b7b7b;
  -webkit-border-radius: 3px;
  cursor: pointer
}

.block-padding-xs{
  padding: 25px 0;
}
.block-padding-lg{
  padding: 60px 0;
}
.menu-slide-right{
  .block-padding{
    padding: 0 0 50px;
  }
}
.filter-black-white{
  filter: grayscale(100%);

}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}








.mr-0 {
  margin-right: 0px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-55 {
  margin-right: 55px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-65 {
  margin-right: 65px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-75 {
  margin-right: 75px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-85 {
  margin-right: 85px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mr-95 {
  margin-right: 95px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-55 {
  margin-left: 55px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-65 {
  margin-left: 65px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-75 {
  margin-left: 75px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-85 {
  margin-left: 85px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-95 {
  margin-left: 95px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-95 {
  margin-top: 95px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-95 {
  margin-bottom: 95px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.pb-0{
  padding-bottom: 0!important;
}
.pt-0{
  padding-top: 0!important;
}

@media all and (max-width: 1023px) {
  .mb-70{
    margin-bottom: 40px!important;
  }

}

ol{
  list-style-type: none;
  counter-reset: num;
  position: relative;
  padding-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  li {
    padding-bottom: 15px;
  }
  li:last-child {
    padding-bottom: 0;
  }
  li:before {
    content: counter(num) '. ';
    counter-increment: num;
    color: $colorBlack;
    font-weight: 700;
    position: absolute;
    left: 0;
  }
}
ul {
  list-style-type: none;
  padding-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  li {
    padding-bottom: 10px;
    position: relative;
  }
  li:before {
    content: '';
    top: 7px;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: #0056b9;
    position: absolute;
    left: -23px;
  }
}



.container {
  margin-left: auto;
  margin-right: auto;
  width: 1370px;
  padding: 0 15px;
}


@media all and (min-width: 290px) {

}
@media all and (min-width: 480px) {

}

.flex-align-end{
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}
.flex-align-start{
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}
.flex-stretch {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
}
.flex-center {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.flex-center-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-justify-end{
  justify-content: flex-end;
}
.flex-justify-center{
  justify-content: center;
}
.flex-align-center{
  align-items: center;
}
.flex-align-end{
  display: flex;
  align-items: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-column-space-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //height: 100%;
}
.container-fluid {
  margin-left: auto;
  margin-right: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
}
.row-align-end{
  align-items: flex-end;
}
.row-align-center {
  align-items: center;
}
.row-justify-center{
  justify-content: center;
}
.row-sm {
  margin-left: -5px;
  margin-right: -5px;
}
.row-sm > [class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xlg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xlg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xlg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xlg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xlg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xlg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xlg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xlg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xlg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xlg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xlg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media all and (min-width: 480px) {
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media all and (min-width: 768px) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media all and (min-width: 1024px) {
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
@media all and (min-width: 1280px) {
  .col-xlg-12 {
    width: 100%;
  }
  .col-xlg-11 {
    width: 91.66666667%;
  }
  .col-xlg-10 {
    width: 83.33333333%;
  }
  .col-xlg-9 {
    width: 75%;
  }
  .col-xlg-8 {
    width: 66.66666667%;
  }
  .col-xlg-7 {
    width: 58.33333333%;
  }
  .col-xlg-6 {
    width: 50%;
  }
  .col-xlg-5 {
    width: 41.66666667%;
  }
  .col-xlg-4 {
    width: 33.33333333%;
  }
  .col-xlg-3 {
    width: 25%;
  }
  .col-xlg-2 {
    width: 16.66666667%;
  }
  .col-xlg-1 {
    width: 8.33333333%;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0%;
  }
}
@media all and (max-width: 479px) {
  .hidden-xs {
    display: none !important;
  }
}
@media all and (min-width: 480px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .hidden-md {
    display: none !important;
  }
}
@media all and (min-width: 1024px) and (max-width: 1240px) {
  .hidden-lg {
    display: none !important;
  }
}
@media all and (min-width: 1241px) {
  .hidden-xlg {
    display: none !important;
  }
}