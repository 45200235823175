.main-text{
  .str404{
    font-size: 160px;
    line-height: 160px;
  }
}
.main-text {
  height: 47%;
  padding-top: 40px;
  .text{
    font-size: 40px;
    line-height: 50px;
  }
}
.gifs{
  height: 53%;
  .bottom-text{
    text-align: center;
    font-size: 18px;
  }
}
.main-text{
  text-align: center;
}
.slider-gif{
  padding-bottom: 70px;
  padding-top: 25px;
  .item{
    img{
      margin: 0 auto;
      width: 86%;
      height: 86%;
      max-width: 300px;
      max-height: 300px;
    }
  }
}


.space{
  .go-galior{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 288px;
    cursor: pointer;
  }
  #gagarin{
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition:opacity 0.3s;
    opacity: 0;
    position: fixed;
    width: 175px;
    height: auto;
    background: url("../img/astronaut.png") top left no-repeat;
    background-size: cover;
    z-index: 6;
  }
  .astronaut{
    position: fixed;
    width: 100%;
    height: 50%;
    top: 100px;
    right: 0;
  }
  .bg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
  }
  .logo-galior{
    width: 315px;
    margin-left: -18px;
    margin-top: 30px;
  }
  .main-text{
    padding-top: 0;
    position: absolute;
    height: 100%;
    right: inherit;
    left: 170px;
    top: 50%;
    transform: translateY(-50%);
    .wrapper-block{
      height: 100%;
      padding-top: 150px;
      padding-bottom: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .text{
      font-family: "Courier New";
      font-size: 40px;
      color: #fff;
    }
    .str404{
      font-size: 100px;
      padding-bottom: 0;
      color: #fff;
      font-weight: 700;
    }
    .wrap-text{
      margin-bottom: 40px;
    }
  }
  .container-sm{
    width: 670px;
    height: 100%;
    text-align: left;
  }
  .bottom-text{
    margin-top: 95px;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    color: #fff;
  }
}


.with-bg{
  .bg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
  }
  .main-text{
    padding-top: 0;
    position: absolute;
    height: auto;
    right: 170px;
    top: 50%;
    transform: translateY(-50%);
    .str404{
      padding-bottom: 120px;
      color: #4d4d4d;
      font-weight: 700;
    }
  }
  .container-sm{
    width: 670px;
  }
  .bottom-text{
    margin-top: 95px;
    text-align: center;
    font-size: 18px;
  }
}




@media all and (max-width: 1420px){
  .container{
    width: 940px;
  }
  .with-bg .main-text {
    right: 15px;
  }
  .with-bg .container-sm {
    width: 575px;
  }
  .space{
    .main-text{
      left: 85px;
    }
  }
}
@media all and (max-width: 1023px){
  .container{
    width: 690px;
  }
  .main-text {
    .text{
      font-size: 29px;
      line-height: 39px;
    }
  }
  .slider-gif{
    padding-bottom: 80px;
  }
  .with-bg .container-sm{
    width: 390px;
  }
  .with-bg{
    .bottom-text {
      margin-top: 60px;
    }
    .main-text{
      .str404{
        padding-bottom: 70px;
      }
      .text{
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  .space{
    #gagarin{
      width: 120px;
    }
    .bottom-text{
      font-size: 22px;
    }
    .logo-galior{
      width: 245px;
      margin-left: -14px;
      margin-top: 17px;
    }
    .main-text{
      left: 60px;
      .text{
        font-size: 30px;
      }
      .wrapper-block{
        padding-bottom: 110px;
        padding-top: 60px;
      }
      .str404{
        font-size: 80px;
        line-height: 150px;
      }
    }
  }
}

@media all and (max-width: 767px){
  .container{
    width: 450px;
  }
  .main-text {
    .str404{
      font-size: 140px;
    }
    .text{
      font-size: 24px;
      line-height: 33px;
    }
  }
  .slider-gif{
    padding-bottom: 60px;
  }
  .with-bg{
    .bg{
      background-position-x: center;
      background-position-y: 57%;

    }
    .container-sm{
      width: 100%;
    }
    .main-text{
      .str404{
        padding-bottom: 40px;
      }
      padding-top: 0px;
      right: 0;
      top: 20px;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      width: 100%;
      .str404{

      }
      .text{

      }
    }
  }
  .space{
    #gagarin{
      width: 100px;

    }
    .container-sm{
      width: 100%;
    }
    .bottom-text{
      font-size: 18px;
      margin-top: 0;

    }
    .logo-galior{
      width: 205px;
      margin-left: -13px;
      margin-top: 12px;

    }
    .main-text{
      left: 25px;

      .text{
        margin-top: 30px;
        font-size: 27px;
      }
      .wrapper-block{
        padding-bottom: 110px;
        padding-top: 150px;
      }
      .str404{
        font-size: 67px;
        line-height: 85px;
      }
    }
  }
}
@media all and (max-width: 479px){
  .container{
    width: 100%;
    padding: 0 15px;
  }
  .main-text {
    height: 40%;
    padding-top: 10px;
    .str404{
      font-size: 120px;
    }
    .text{
      font-size: 23px;
      line-height: 29px;
    }
  }
  .gifs {
    height: 60%;
    .bottom-text{
      font-size: 16px;
    }
  }
  .slider-gif {
    padding-bottom: 38px;
  }

  .with-bg{
    .bg{


    }
    .bottom-text{
      font-size: 16px;
      margin-top: 30px;
    }
    .container-sm{
      padding: 0 15px;

    }
    .main-text{
      .str404{
        font-size: 110px;
        line-height: 120px;
      }

      .str404{

      }
      .text{
        font-size: 20px;
        line-height: 24px;

      }
    }
  }
  .space{
    #gagarin{
        width: 90px;
    }
    .main-text{
      left: 15px;
      .text{

      }
      .wrapper-block{
        padding-bottom: 133px;
        padding-top: 115px;
      }
    }
  }
}


















@media all  and (max-height: 800px){
  .main-text {
    height: 49%;
    padding-top: 10px;
    .text{
      font-size: 32px;
      line-height: 36px;
    }
  }
  .container{
    width: 940px;
  }
  .gifs{
    height: 51%;
  }
  .slider-gif{
    padding-top: 15px;
    padding-bottom: 40px;
    .item{
      img{
        max-height: 200px;
        max-width: 200px;
      }

    }
  }
  .space{
    .bg{
      background-position: center 87%;
    }
    .bottom-text{
      font-size: 17px;
      line-height: 25px;
    }
    .logo-galior{
      width: 170px;
      margin-left: -11px;
      margin-top: 5px;
    }
    .go-galior{
      height: 235px;
    }
    .main-text{

      .text{
        font-size: 30px;
        line-height: 35px;
      }
      .wrapper-block{
        padding-top: 60px;
        padding-bottom: 20px;
      }
      .str404{
        font-size: 85px;
        line-height: 115px;
      }
    }
  }
}

@media all  and (max-height: 600px){
  .main-text {
    height: 43%;
    .str404{
      font-size: 68px;
      line-height: 77px;
    }
    .text{
      font-size: 23px;
      line-height: 26px;
    }
  }
  .container{
    width: 800px;
  }
  .gifs{
    height: 57%;
  }
  .slider-gif{
    padding-bottom: 57px;
    .item{
      img{
        max-height: 130px;
        max-width: 130px;
      }

    }
  }
  .with-bg{
    .bottom-text{
      margin-top: 30px;
    }
    .main-text{
      .str404{
        font-size: 99px;
        line-height: 110px;
        padding-bottom: 20px;
      }
    }
  }
  .space{
    .bg{
      background-position: center 67%;
    }
    .go-galior{
      height: 180px;
    }
    #gagarin{
      width: 100px;
    }
    .bottom-text{
      margin-top: 0;
    }
    .logo-galior{

    }
    .main-text{

      .text{
        font-size: 25px;
        line-height: 30px;
      }
      .wrapper-block{
        padding-top: 25px;
      }
      .str404{
        line-height: 103px;
      }
    }
  }
}

@media all  and (max-height: 450px){
  .slider-gif {
    padding-bottom: 27px;
  }
}



@media all  and (max-height: 800px) and (max-width: 1023px){
  .container{
    width: 690px;
  }
}
@media all  and (max-height: 600px) and (max-width: 1023px){
  .container {
    width: 522px;
  }
}
@media all  and (max-height: 400px) and (max-width: 1023px){
  .main-text {
    padding-top: 5px;
    .str404{
      font-size: 55px;
      line-height: 60px;
    }
    .text{
      font-size: 16px;
      line-height: 18px;
    }
  }
  .container {
    width: 346px;
  }
  .gifs{
    .bottom-text{
      font-size: 14px;
    }
  }
  .slider-gif{

    .item{
      img{
        max-height: 80px;
        max-width: 80px;
      }
    }
  }
  .space{
    .go-galior{
      height: 120px;
    }
  }
}
@media all  and (max-height: 800px) and (max-width: 767px){
  .container{
    width: 450px;
  }
  .main-text {
    .text{
      font-size: 24px;
      line-height: 33px;
    }
  }
  .with-bg{
    .bottom-text{
      margin-top: 20px;
    }
    .main-text{
      .str404{
        padding-bottom: 8px;
      }
    }

  }
}


@media all  and (max-height: 600px) and (max-width: 767px){
  .gifs{
    .container{
      width: 400px;
    }
  }

}

@media all  and (max-height: 450px) and (max-width: 767px){
  .with-bg{
    .bottom-text{
      font-size: 17px;
    }
    .main-text{
      .text{
        font-size: 19px;
        line-height: 20px;
      }
      .str404{
        font-size: 65px;
        line-height: 71px;
      }
    }
  }
  .space{
    #gagarin{
      width: 70px;
    }
    .main-text{
      .text{
        font-size: 20px;
        line-height: 25px;
        margin-top: 8px;
      }
      .str404{
        font-size: 62px;
        line-height: 65px;
      }
    }
  }

}

@media all  and (max-height: 400px) and (max-width: 767px){
  .main-text {
    .str404{
      font-size: 44px;
      line-height: 45px;
    }
    .text{
      font-size: 16px;
      line-height: 22px;
    }
  }

  .gifs .container {
    width: 279px;
  }
  .slider-gif{
    padding-bottom: 13px;
  }
}


@media all  and (max-height: 800px) and (max-width: 479px){
  .container{
    width: 100%;
  }
  .main-text {
    .text{
      font-size: 23px;
      line-height: 29px;
    }
  }
}
@media all  and (max-height: 600px) and (max-width: 479px){
  .gifs .container {
    width: 100%;
  }
  .main-text{
    .text{
      font-size: 16px;
      line-height: 23px;
    }
  }

}

@media all  and (max-height: 400px) and (max-width: 479px){
  .with-bg{
    .bottom-text{
      margin-top: 7px;
      font-size: 15px;
    }
    .main-text{
      top: 5px;
      .text{
        font-size: 16px;
        line-height: 18px;
      }
      .str404{
        padding-bottom: 5px;
        font-size: 53px;
        line-height: 53px;
      }
    }

  }

}

